







































































































































.branch {
  position: relative;
  background: #ebebeb;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}
