


































































































































































































#send-email-modal .modal-dialog{
  max-width: 600px;
}
